import moment from "moment-timezone"
import { context } from "./context/variables"

// ** Toastify
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// ** MUI
import { Backdrop, CircularProgress } from "@mui/material"

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 3,
}

// ** DATE/TIME RELATED ** //
export const getFormattedDate = (date) => {
  let splitter = date.includes("T") ? "T" : " "
  let splitUp = date.split(splitter)[0]
  return splitUp
}

export const getTimezoneFormattedDate = (date, withTime) => {
  let m = moment.utc(date)
  if (!date) return ""
  else {
    if (withTime) {
      m.tz(context.userTimezone)
      return m.format("MM-DD-YYYY HH:mm z")
    } else return m.format("MM-DD-YYYY")
  }
}

// Determines if string is a valid date containing 'HH:mm:ss AM/PM'.
export const timeRegexValidation = (str) => {
  let splitStr = str.split(" ")
  const validTime = new RegExp(/^(?:[01]?[\d]|2[0-3]):[0-5]?[\d](?::[0-5]?[\d])?$/)
  return validTime.test(splitStr[1]) && (splitStr[2] === "AM" || splitStr[2] === "PM") ? true : false
}

export const SetTimeAndOffset = (date) => {
  let today = new Date()

  // Grab the current time.
  let currentTime = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
  const tzOffsetNumber = today.getTimezoneOffset()
  const tzDate = new Date(0, 0, 0, 0, Math.abs(tzOffsetNumber))
  let time = `${currentTime}${tzOffsetNumber > 0 ? "-" : "+"}${tzDate.getHours()}:${("" + tzDate.getMinutes()).padStart(2, "0")}`
  let newDateTimeFormat = date ? `${getFormattedDate(date)} ${time}` : time
  return newDateTimeFormat
}
// ** END OF DATE/TIME RELATED ** //

// Triggers a file download.
export const triggerDownload = (url, filename) => {
  // Create a temporary <a> element to trigger download.
  const tempLink = document.createElement("a")
  tempLink.href = url

  // Set filename for the downloaded file.
  tempLink.setAttribute("download", filename)

  // Append the <a> element to the body and click to trigger download.
  document.body.appendChild(tempLink)
  tempLink.click()

  // Clean up the temporary elements and URL.
  document.body.removeChild(tempLink)
}

// Determines if user can access a feature based on the roles they're in
// and roles in rolesNotPermitted.
export const permissionCheck = (rolesNotPermitted, usersRoles) => {
  if (usersRoles) return usersRoles.some((f) => rolesNotPermitted.includes(f))
  else return true
}

export const getPathname = (path) => {
  return path.split("/")[1]
}

export const applyCaps = (str) => {
  const words = str.split("_")
  return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
}

export const formatEnum = (str) => {
  if (str === "LAMS") return str
  else {
    let lowercaseStr = str.toLowerCase()
    const words = lowercaseStr.split("_")
    return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
  }
}

export const filterPendingChanges = async (data, userPrograms) => {
  let array = userPrograms.map((program) => program.name)
  return data.filter((change) => array.includes(change.program))
}

export const filterActiveData = async (data) => {
  return data.filter((el) => el.activeState)
}

export const filterByIdActiveState = async (data, id) => {
  return data.filter((el) => el.id !== id && el.activeState)
}

// Creates longer queries specifically for Inventory/Issued Records using OData.
export const createQuery = (entity, values) => {
  let prefix = entity === "InventoryRecords" ? "ManagementRecordArmyProgramId%20eq%20" : "programName%20eq%20"
  let queries =
    entity === "InventoryRecords" ? values.map((id) => prefix + id + "%20and%20Quantity%20gt%200") : values.map((name) => prefix + `%27${name}%27`)
  return queries.join("%20or%20")
}

export const getProgramName = (apId, programsToIterate) => {
  if (apId) {
    let foundAP = programsToIterate?.find((el) => el.id === apId)
    return foundAP?.name
  } else {
    return ""
  }
}

export const getWarehouseBuildingNumber = (whId, warehousesToIterate) => {
  if (whId) {
    let foundWH = warehousesToIterate?.find((el) => el.id === whId)
    return foundWH?.buildingNumber
  } else {
    return ""
  }
}

export const getProgramObj = (apId, programsToIterate) => {
  if (apId) {
    let foundAP = programsToIterate?.find((el) => el.id === apId)
    return foundAP
  } else {
    return ""
  }
}

export const notify = (result, message) =>
  toast[result](`${message}`, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  })

export const applySignatureCaps = (str) => {
  const words = str.split(/(?=[A-Z])/)
  let revised = words.map((word) => {
    let updated =
      word !== "Signature" && word.length > 2
        ? word[0].toUpperCase() + word.substring(1)
        : word !== "Signature" && word !== "In" && word.length === 2
          ? word.toUpperCase()
          : word === "Signature" || word === "In"
            ? word
            : null
    return updated
  })
  return revised.join(" ")
}

export const determineDateFieldToUpdate = (signature) => {
  return signature.includes("parts")
    ? "partsDate"
    : signature.includes("ti")
      ? "tiDate"
      : signature === "qaSignature"
        ? "qaDate"
        : signature.includes("lead")
          ? "teamLeadDate"
          : signature.includes("hazmat")
            ? "hazmatDate"
            : signature === "transportationSignature"
              ? "transportationDate"
              : signature.includes("turnIn")
                ? "turnInDate"
                : signature.includes("final")
                  ? "finalDepartureDate"
                  : null
}

export const shortenRequestType = (str) => {
  const words = str.split(" ")
  return words[0]
}

export const propertyFormatter = (param) => {
  // Remove the '.Value' from the property name.
  let newPropertyName = param.value.replace(".Value", "")

  // Rename some properties for legibility.
  switch (newPropertyName) {
    case "MethodOfPreservationArmy":
      return (newPropertyName = "ARMY MOP")
    case "ControlledInventoryItemCodeAmdf":
      return (newPropertyName = "CIIC AMDF")
    case "HazardousCharacteristicsCode":
      return (newPropertyName = "HCC")
    case "TypeOfStorageArmy":
      return (newPropertyName = "TOS ARMY")
    case "MaterielCategoryStructureFourAndFive":
      return (newPropertyName = "MATCAT 4 & 5")
    case "SpecialRequirementsCode":
      return (newPropertyName = "SRC")
    case "ShelfLifeCode":
      return (newPropertyName = "SLC")
    case "SupplyCategoriesOfMaterielCode":
      return (newPropertyName = "SCMC")
    case "SpecialControlItemCode":
      return (newPropertyName = "SCIC")
    case "HazardousMaterielIndicatorCode":
      return (newPropertyName = "HMIC")
    case "RecoverabilityCode":
      return (newPropertyName = "Recoverability Code")
    case "DemilitarizationCode":
      return (newPropertyName = "DEMIL Code")
    case "CriticalityCode":
      return (newPropertyName = "CRITL Code")
    case "ControlledInventoryItemCode":
      return (newPropertyName = "CIIC")
    case "AccountingRequirementsCode":
      return (newPropertyName = "ARC")
    case "AutomaticReturnItemList":
      return (newPropertyName = "ARIL")
    case "SourceOfSupply":
      return (newPropertyName = "SOS")
    case "ArmyEnterpriseSystemIntegrationProgramSerializationIndicator":
      return (newPropertyName = "AESIP Serialization Indicator")
    case "CommercialAndGovernmentEntityCode":
      return (newPropertyName = "CAGE Code")
    case "FederalSupplyClassification":
      return (newPropertyName = "FSC")
    case "ManagementRecordWarehouseId":
      return (newPropertyName = "Warehouse")
    case "ManagementRecordArmyProgramId":
      return (newPropertyName = "Program")
    case "ActiveState":
      return (newPropertyName = "Active")
    default:
      break
  }

  // Split Pascal Case property name (if applicable).
  const regex = (str) => str.replace(/(?<=[a-z\d])(?=[A-Z])|(?<=[A-Z])(?=[A-Z][a-z])/g, " ")

  return regex(newPropertyName)
}

// Encode any special characters. Primarly used for values that have special chars in OData queries.
export const replaceSpecialCharacters = (valueWithSpecChars) => {
  let encodedValue = valueWithSpecChars
  encodedValue = encodedValue.replace(/%/g, "%25")
  encodedValue = encodedValue.replace(/\+/g, "%2B")
  encodedValue = encodedValue.replace(/\//g, "%2F")
  encodedValue = encodedValue.replace(/\?/g, "%3F")
  encodedValue = encodedValue.replace(/#/g, "%23")
  encodedValue = encodedValue.replace(/&/g, "%26")
  return encodedValue
}

// Get Base64 string of uploaded file.
export const getBase64 = (file) => {
  return (resolve) => {
    let fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      let dataUrl = fileReader.result

      // Remove 'data:*/*;base64,' part of string.
      let base64String = dataUrl.split("base64,").pop()
      resolve(base64String)
    }
  }
}

export const createEmailBody = (processType, programName, documentNumber, status, signature, containsAssetId) => {
  // NOTE: Get the program's ID in Keycloak; not to be confused with the ID in the database.
  const keycloakProgram = context.clientGroupData.filter((f) => f.name === programName)

  let body = {
    processType: processType,
    program: programName,
    programId: keycloakProgram[0].id,
    documentNumber: documentNumber,
    status: status,
    signature: signature,
    containsAssetId: containsAssetId,
  }

  return body
}

// For LoadingBackdrop; this prevents clicking on any elements underneath the overlay.
export const convertToClick = (e) => {
  const evt = new MouseEvent("click", { bubbles: true })
  evt.stopPropagation = () => {}
  e.target.dispatchEvent(evt)
}

export const isRequestLocal = (type) => {
  return type === "Movement Request - Local Delivery" || type === "Pickup Request - Local Pickup only"
}

export default function LoadingBackdrop({ leftMenuDrawerOpen }) {
  return (
    <div
      style={{ position: "fixed", minHeight: "100%", zIndex: 2 }}
      onMouseUp={convertToClick}
    >
      <Backdrop
        sx={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        open={true}
      >
        {!leftMenuDrawerOpen ? (
          <CircularProgress
            sx={{ position: "absolute" }}
            size="3.5rem"
          />
        ) : (
          <CircularProgress
            sx={{ position: "absolute", left: "53%" }}
            size="3.5rem"
          />
        )}
      </Backdrop>
    </div>
  )
}
